import React, { useState, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import GoogleMap from "../GoogleMap";
import MarkerJSON from "../../data/markers.json";
import { useNoRenderRef } from "../../hooks/index.js";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const MapCard = (props) => {
  const [zoom, setZoom] = useState(15); // initial zoom
  const [center, setCenter] = useState({ lat: 18.513148, lng: -67.079721 });
  const [places, setPlaces] = useState([]);
  const [activePlaceId, setActivePlaceId] = useState(-1);
  const placesRef = useNoRenderRef(places);

  useEffect(() => {
    placesRef.current = [...MarkerJSON];
    setPlaces(placesRef.current);
  }, [MarkerJSON, placesRef]);

  useEffect(() => {
    placesRef.current.forEach((place) => {
      if (place.id === activePlaceId) {
        place.selected = true;
      } else {
        place.selected = false;
      }
      return place;
    });
    setPlaces([...placesRef.current]);
  }, [activePlaceId, placesRef]);

  const onIdle = (map) => {
    if (map && map?.getZoom() !== null && map?.getCenter() !== null) {
      setZoom(map.getZoom());
      setCenter(map.getCenter()?.toJSON());
    }
  };

  const handleCardClick = (placeId) => {
    console.warn("index handle click place");
    setActivePlaceId(placeId);
  };

  const handleMarkerClick = ({ id }) => {
    console.warn("index handle click marker");
    setActivePlaceId(id);
  };

  const markers = useMemo(
    () =>
      places.map(({ id, position, type, selected, name, phone, address }) => {
        return { id, position, type, selected, name, phone, address };
      }),
    [places]
  );

  return (
    <Container>
      <GoogleMap
        center={center}
        zoom={zoom}
        onIdle={onIdle}
        markers={markers}
        onMarkerClick={handleMarkerClick}
        selectedMarkerId={activePlaceId}
      />
    </Container>
  );
};

MapCard.propTypes = {};

export default MapCard;
