import React from "react";
import MapCard from "./MapCard";

const DetailMap = () => {
  return (
    <div className="gradient-blue-white px-4 pb-6  border-b-[1px] border-background">
      <div className="grid h-[343px] ">
        <MapCard />
      </div>
    </div>
  );
};

export default DetailMap;
