import React from "react";
import Fade from "react-reveal/Fade";
import { Container1440 } from "../common/global.styled";
import data from "../../data/contact.json";
import FooterContact from "../FooterContact";

const letterClass = "text-neutral-light-grey typo-body font-bold";
const textClass = "text-neutral-light-grey typo-body";
const Details = () => {
  return (
    <div className="w-full bg-blues-dark-blue">
      <Container1440 className="pt-10 pb-6 pl-10 pr-16">
        <div className="pr-8">
          <Fade bottom>
            <p className="typo-h4 text-blues-light-blue">
              FOR LEASING
              <br />
              OPPORTUNITIES
            </p>
          </Fade>
        </div>
        <div className="text-neutral-light-grey typo-body pt-8">
          <Fade bottom>
            <FooterContact
              textClass={textClass}
              letterClass={letterClass}
              {...data.leasing[0]}
            />
            {/* <FooterContact
              textClass={textClass}
              letterClass={letterClass}
              {...data.leasing[1]}
            /> */}
            <FooterContact
              textClass={textClass}
              letterClass={letterClass}
              {...data.leasing[2]}
            />
            <br />
            <FooterContact
              textClass={textClass}
              letterClass={letterClass}
              {...data.leasing[3]}
            />
          </Fade>
        </div>
        <div className="pt-[72px]">
          <Fade bottom>
            <p className="typo-h4 text-blues-light-blue">
              BUILDING
              <br />
              OPERATION HOURS
            </p>
          </Fade>
        </div>
        <div className="text-neutral-light-grey typo-body pt-8">
          <Fade bottom>
            <p>
              <b>Monday through Friday</b>
              <br /> 6am - 6pm
            </p>
            <br />
            <p>
              <b>Monday through friday</b>
              <br /> 6:00 pm - 6:00 am
              <br />
              Controlled access with security code in each floor
            </p>
            <br />
            <p>
              <b>Saturdays</b>
              <br /> 7am - 4pm
            </p>
          </Fade>
        </div>
      </Container1440>
    </div>
  );
};

export default Details;
