import React from "react";
import Fade from "react-reveal/Fade";

const Header = () => {
  return (
    <div className="relative">
      <div className="w-full bg-neutral-white pt-20 pb-[25px] px-4">
        <Fade bottom>
          <p className="typo-h0 text-blues-light-blue pt-28 pb-0 break-words">
            GET IN
            <br /> TOUCH
          </p>
        </Fade>
      </div>
    </div>
  );
};

export default Header;
