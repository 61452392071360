import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/contact/Header";
import Details from "../components/contact/Details";
import HeaderDesktop from "../components/contact/HeaderDesktop";
import DetailsDesktop from "../components/contact/DetailsDesktop";
import DetailMap from "../components/contact/DetailMap";

const Contact = () => {
  const breakpoints = useBreakpoint();
  return (
    <Layout>
      <Seo title="Spaces" />
      {breakpoints.sm ? <Header /> : <HeaderDesktop />}
      {breakpoints.sm ? <Details /> : <DetailsDesktop/>}
      {breakpoints.sm && <DetailMap/>}
    </Layout>
  );
};

export default Contact;
