import React from "react";
import Fade from "react-reveal/Fade";
import { Container1440Responsive } from "../common/global.styled";
import MapCard from "./MapCard";
import { Link } from "gatsby";
import BlueBigLogo from "../common/logo/BlueBigLogo";

const HeaderDesktop = () => {
  return (
    <div className="relative">
      <div className="w-full bg-neutral-white">
        <Container1440Responsive className="pt-10 grid grid-cols-[40%_60%]">
          <div className="pr-[90px]">
            <Link to="/" id="for-test">
            <BlueBigLogo width="150" height="23"/>
            </Link>
            <Fade bottom>
              <p className="typo-h0 text-blues-light-blue py-28 break-words">
                GET IN
                <br /> TOUCH
              </p>
            </Fade>
          </div>
        </Container1440Responsive>
      </div>
      <div className="w-full bg-blues-dark-blue h-20"></div>
      <div className="w-full absolute top-0 h-full pointer-events-none">
        <Container1440Responsive className="pt-10 grid grid-cols-[40%_60%] h-full">
          <div></div>
          <Fade bottom>
            <div style={{ pointerEvents: "all" }}>
              <MapCard />
            </div>
          </Fade>
        </Container1440Responsive>
      </div>
    </div>
  );
};

export default HeaderDesktop;
